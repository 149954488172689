import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LambdaProductListBuilder from './LambdaProductListBuilder'
import LambdaSanityToS3 from './LambdaSanityToS3'
const LambdaRunner = () => {
  return (
    <>
      <Accordion elevation={0} variant="outlined" defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">services-sanityToS3</Typography>
        </AccordionSummary>
        <div style={{ padding: '16px' }}>
          <Typography variant="body1">
            The list of S3 will be refreshed, including: shop.json, shopNew.json, brand.json, pushNotifications.json,
            homePageContentShops.json, landingPage.json, ourcommitments.json, OPTIN_YES.json
          </Typography>
          <LambdaSanityToS3 />
        </div>
      </Accordion>
      <Accordion elevation={0} variant="outlined" defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">services-productListBuilder</Typography>
        </AccordionSummary>
        <div style={{ padding: '16px' }}>
          <Typography variant="body1">
            The list of S3 will be refreshed, including: merchandisings.json, all-merchs-of-selected-brand.json,
            products.json
          </Typography>
          <LambdaProductListBuilder />
        </div>
      </Accordion>
    </>
  )
}

export default LambdaRunner
