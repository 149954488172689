import { FormControl, MenuItem, Select, Grid, InputBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { fetchBrandShopCode } from '../../api/sanityClient'
import { lambdaProductListBuilderName, invokeLambda } from '../../api/backendClient'
import { toast } from 'react-toastify'
import ButtonGeneric from './../ButtonGeneric'
import Sync from '@material-ui/icons/Sync'
import { shop } from '../../model/shop'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const LambdaProductListBuilderButton = ({ brandShop }: { brandShop: string }) => {
  const [isRunning, setIsRunning] = useState(false)
  const hasBrandShop = brandShop && brandShop?.length > 0

  const runLambda = () => {
    setIsRunning(true)
    runLambdaPayloads({
      payloads: [
        {
          brandCode: `${brandShop}`,
        },
      ],
      lambdaName: lambdaProductListBuilderName,
      onFinally: () => setIsRunning(false),
      onSuccess: () =>
        toast.success(
          `productListBuilder was launched for ${brandShop}. It may take over 15mins to complete the work.`
        ),
      onFailure: () => toast.error('Update failed, see console for errors'),
      onTimeout: () =>
        toast.warning(
          'Failed to get server response in time, the process might still be running. Please check if it worked in a few minutes.'
        ),
    })
  }

  return (
    <ButtonGeneric label="Refresh" isDisabled={isRunning || !hasBrandShop} onClick={runLambda} startIcon={<Sync />} />
  )
}
const runLambdaPayloads = async (args: {
  payloads: any[]
  lambdaName: string
  onFinally: () => void
  onSuccess: () => void
  onFailure: () => void
  onTimeout: () => void
}) => {
  try {
    const responses = await Promise.all(args.payloads.map(payload => invokeLambda(payload, args.lambdaName)))
    const failedInvokes: { payload: any; error: string }[] = []
    responses.forEach((response, i) => {
      if (response.status !== 'SUCCESS') {
        failedInvokes.push({
          payload: args.payloads[i],
          error: response.error,
        })
      }
    })
    if (failedInvokes.length) {
      console.error(failedInvokes)
      args.onFailure()
    } else {
      args.onSuccess()
    }
  } catch (error) {
    console.error(error)
    args.onTimeout()
  } finally {
    args.onFinally()
  }
}

const computedExceptionShop = (sanityShop: { id: string; name: string }[]) =>
  sanityShop?.map((shop: { id: string; name: string }) => {
    if (shop?.id === 'AFHOFRFR') {
      return {
        id: 'AF fr-FR',
        name: shop?.name,
      }
    }
    return shop
  })

const LambdaProductListBuilder = () => {
  const [shops, setShops] = useState<shop[]>([])
  const [selectedShop, setSelectedShop] = useState<string>('AF fr-FR')
  useEffect(() => {
    fetchBrandShopCode()
      .then(sanityShops => {
        setShops(computedExceptionShop(sanityShops))
      })
      .catch(error => {
        console.error(error)
        toast.error('Failed to retrive shops, please see console for error')
      })
  }, [])
  return (
    <Grid item container spacing={2} style={{ display: 'flex', alignItems: 'center', minHeight: '80px' }}>
      <Grid item xs={3}>
        <FormControl className="fullWidth" variant="outlined" required={true}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={selectedShop}
            onChange={event => setSelectedShop(event?.target?.value as string)}
            input={<BootstrapInput />}
          >
            {shops.map(s => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <LambdaProductListBuilderButton brandShop={selectedShop} />
      </Grid>
    </Grid>
  )
}

export default LambdaProductListBuilder
